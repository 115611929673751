<template>
    <dropdown-wrapper dropdown-classes="tw-w-56">
        <template v-slot:menu-button>
            <MenuButton
                class="tw-rounded tw-mx-2 tw-py-1 tw-bg-white tw-whitespace-nowrap tw-text-gray-900 tw-text-sm tw-font-normal tw-leading-relaxed hover:tw-text-black focus:tw-text-black hover:tw-no-underline tw-flex tw-items-center tw-border-opacity-0">
                &nbsp;<i class="far fa-bars fa-fw tw-text-lg tw-w-8"></i>&nbsp;
            </MenuButton>
        </template>
        <template v-slot:menu-items>
            <h4 class="tw-text-gray-400 tw-text-sm tw-font-semibold tw-px-4 tw-pt-2 tw-uppercase">Tjänster</h4>
            <MenuItem v-slot="{ active }" v-for="menuItem in serviceItems" :class="[menuItem.hideDesktop && 'desktop:tw-hidden']" :disabled="menuItem.hideDesktop && isDesktop">
                <a role="button" :class="[active && 'tw-text-allabolag-700 tw-underline']"
                   class="tw-block tw-w-full tw-px-4 tw-py-2" :href="menuItem.link">
                    {{ menuItem.text }}
                </a>
            </MenuItem>
            <h4 class="tw-text-gray-400 tw-text-sm tw-font-semibold tw-px-4 tw-pt-8 tw-uppercase">
                Om allabolag.se</h4>
            <MenuItem v-slot="{ active }" v-for="menuItem in aboutItems">
                <a role="button" :class="[active && 'tw-text-allabolag-700 tw-underline']"
                   class="tw-block tw-w-full tw-px-4 tw-py-2" :href="menuItem.link">
                    {{ menuItem.text }}
                </a>
            </MenuItem>
            <h4 v-if='userItems.length > 0' class="tw-text-gray-400 tw-text-sm tw-font-semibold tw-px-4 tw-pt-8 tw-uppercase">Användare</h4>
            <MenuItem v-slot="{ active }" v-for="menuItem in userItems">
                <form v-if="menuItem.loggedIn" :action="menuItem.link" method="post">
                    <input type="hidden" name="_token" :value="csrf" />
                    <button
                        :class="[active && 'tw-text-allabolag-700 tw-underline']"
                        class="tw-block tw-w-full tw-text-left tw-py-2 tw-px-4 tw-font-bold tw-text-allabolag-600 hover:tw-text-green-900 hover:tw-underline focus:tw-text-green-900 focus:tw-underline focus:tw-outline-none">
                        {{ menuItem.text }}
                    </button>
                </form>
                <a v-else role="button" :class="[active && 'tw-text-allabolag-700 tw-underline']"
                   class="tw-block tw-w-full tw-px-4 tw-py-2" :href="menuItem.link">
                    {{ menuItem.text }}
                </a>
            </MenuItem>
        </template>
    </dropdown-wrapper>
</template>
<script>
import {MenuButton, MenuItem} from "@headlessui/vue";
import DropdownWrapper from "./DropdownWrapper.vue";

export default {
    components: {
        DropdownWrapper,
        MenuButton,
        MenuItem,
    },
    props: {
        menuItems: Array,
        isLoggedIn: Boolean
    },
    data() {
        return {
            windowWidth: window.innerWidth
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
        })
    },
    computed: {
        serviceItems() {
            return this.menuItems.filter(item => item.type === "service");
        },
        aboutItems() {
            return this.menuItems.filter(item => item.type === "about");
        },
        userItems() {
            return this.menuItems.filter(item => item.type === "user" && item.loggedIn === this.isLoggedIn);
        },
        isDesktop() {
            return this.windowWidth > 768;
        },
        csrf() {
            return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        }
    }
}
</script>
